import axios from 'axios';

const BASE_URL = process.env.VUE_APP_BASE_URL_NERFETARI;

export default axios.create({
    baseURL: BASE_URL,
    headers: {
        'Api-key': process.env.VUE_APP_API_KEY_NERFETARI,
    },
    timeout: 0,
});
